<script setup lang="ts">
import { cn } from "@lib/utils"
import { useVModel } from "@vueuse/core"
import { useAttrs } from "vue"

defineOptions({
    inheritAttrs: false,
})

const props = defineProps<{
    defaultValue?: string | number | []
    modelValue?: string | number | []
}>()

const emits = defineEmits<{
    (e: "update:modelValue", payload: string | number | []): void
}>()

const { class: className, ...rest } = useAttrs()

const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
})
</script>

<template>
    <input
        v-model="modelValue"
        :class="
            cn(
                'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                className ?? ''
            )
        "
        v-bind="rest"
    />
</template>

<script setup lang="ts">
import { toHuman } from "@helpers/formatters/currency"
import { toHuman as dateToHuman } from "@helpers/formatters/datetime"
import AppLayout from "@layouts/AppLayout.vue"
import { router, useForm } from "@inertiajs/vue3"
import { ref } from "vue"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { openDialog } from "@/components/ui/frames"
import DataTable from "@components/DataTable.vue"
import { columns } from "@components/columns"

defineOptions({
    layout: [AppLayout],
})

const date = new Date()
const form = useForm({
    type_id: 1,
    name: "",
    date: date.toJSON().slice(0, 10),
    amount: "",
})

type Props = {
    types: Types
    transactions: Transactions
    employees: Employees
    links: Links
    totalSales: totalSales
    totalExpenses: totalExpenses
    branch: branch
    start: start
    end: end
}

const { links, types, employees } = defineProps<Props>()
const open = ref(false)

console.log(employees)

function handleAddTransactionClick(event) {
    if (event.shiftKey || event.metaKey || event.ctrlKey || event.altKey) {
        return
    }

    event.preventDefault()
    openDialog({
        url: "/transactions/create",
        onClose: () => {
            router.reload()
        },
    })
}
</script>

<template>
    <div v-if="start && end" class="mx-auto max-w-7xl sm:px-6 lg:px-8 my-10">
        <div class="flex items-center justify-between space-y-2">
            <div>
                <h2 class="text-3xl font-bold tracking-tight capitalize">{{ branch }} Sales and Expenses Report</h2>
                <p class="text-muted-foreground">{{ dateToHuman(start) }} - {{ dateToHuman(end) }}</p>
            </div>
        </div>

        <div class="grid gap-4 grid-cols-2 lg:grid-cols-4 mt-6">
            <Card>
                <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle class="text-sm font-medium"> Sales </CardTitle>
                    <span class="h-4 w-4 text-muted-foreground">₱</span>
                </CardHeader>
                <CardContent>
                    <div class="text-2xl font-bold">
                        {{ toHuman(totalSales / 100) }}
                    </div>
                </CardContent>
            </Card>
            <Card>
                <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle class="text-sm font-medium"> Expenses </CardTitle>
                    <span class="h-4 w-4 text-muted-foreground">₱</span>
                </CardHeader>
                <CardContent>
                    <div class="text-2xl font-bold">
                        {{ toHuman(totalExpenses / 100) }}
                    </div>
                </CardContent>
            </Card>
        </div>

        <div class="h-full flex-1 flex-col space-y-8 md:flex mt-10">
            <div class="space-y-4">
                <div class="rounded-md border">
                    <div class="w-full overflow-auto">
                        <table class="w-full caption-bottom text-sm">
                            <thead class="[&_tr]:border-b">
                                <tr class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Name</th>
                                    <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Amount</th>
                                </tr>
                            </thead>
                            <tbody class="[&_tr:last-child]:border-0">
                                <tr v-for="row in employees" :key="row.id" class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                        {{ row.name }}
                                    </td>
                                    <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                        {{ toHuman(row.salary / 100) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="h-full flex-1 flex-col space-y-8 md:flex mt-10">
            <div class="space-y-4">
                <div class="rounded-md border">
                    <div class="w-full overflow-auto">
                        <table class="w-full caption-bottom text-sm">
                            <thead class="[&_tr]:border-b">
                                <tr class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Date</th>
                                    <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Name</th>
                                    <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Type</th>
                                    <th class="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Amount</th>
                                </tr>
                            </thead>
                            <tbody class="[&_tr:last-child]:border-0">
                                <tr v-for="row in transactions.data" :key="row.id" class="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                        {{ dateToHuman(row.date) }}
                                    </td>
                                    <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                        {{ row.name }}
                                    </td>
                                    <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                        {{ row.type.name }}
                                    </td>
                                    <td class="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                        {{ toHuman(row.amount_in_cents / 100) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="mx-auto max-w-7xl sm:px-6 lg:px-8 my-10">
        <div class="flex items-center justify-between space-y-2">
            <div>
                <h2 class="text-3xl font-bold tracking-tight">No Result</h2>
                <p class="text-muted-foreground">Please select start and end date from the dashaboard.</p>
            </div>
        </div>
    </div>
</template>

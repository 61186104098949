<script lang="ts" setup>
import { cn } from "@lib/utils"
import { Label, type LabelProps } from "radix-vue"
import { inject, useAttrs } from "vue"
import { FORM_ITEM_ERROR_INJECTION_KEY } from "./FormItem.vue"
import { useFormField } from "./useFormField"

defineOptions({
    inheritAttrs: false,
})

const props = defineProps<LabelProps>()
const error = inject(FORM_ITEM_ERROR_INJECTION_KEY)
const { formItemId } = useFormField()
const { class: className, ...rest } = useAttrs()
</script>

<template>
    <Label :class="cn('block text-sm tracking-tight font-medium text-foreground text-left', error && 'text-destructive', className ?? '')" :for="formItemId" v-bind="rest">
        <slot />
    </Label>
</template>

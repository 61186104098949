import { usePage } from "@inertiajs/vue3"
import { computed } from "vue"

const page = usePage()

export const links = computed(() => page.props.inertiaLinks)

export const types = computed(() => page.props.types)

export const employees = computed(() => page.props.employees)

export const branches = computed(() => page.props.branches)

<script lang="ts" setup>
import { PlusCircledIcon } from "@radix-icons/vue"
import { Prop, computed, ref, watch } from "vue"
import { Badge } from "@/components/ui/badge"
import { Command, CommandEmpty, CommandGroup, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Separator } from "@/components/ui/separator"
import { Button } from "@components/ui/button"
import { Input } from "@components/ui/input"

const emit = defineEmits(["update:modelValue"])

// type Props = {
//     modelValue: string[]
// }

// const props = defineProps<Props>()
// const selectedBranches = ref(props.modelValue)
// watch(selectedBranches, (branches) => emit('update:modelValue', branches))
// const selectedBranches = computed({
//     get: () => props.modelValue,
//     set: (branches) => emit('update:modelValue', branches)
// })
// const selectedBranches = defineModel() or
const selectedBranches = defineModel("modelValue")
// const selectedBranches = defineModel('branches')

const AvailableBranches = [
    {
        id: 1,
        value: "malabon",
        label: "malabon",
    },
    {
        id: 2,
        value: "la union",
        label: "la union",
    },
]
</script>
<template>
    <Popover>
        <PopoverTrigger as-child>
            <Button variant="outline" size="sm" class="h-8 border-dashed">
                <PlusCircledIcon class="mr-2 h-4 w-4" />
                Branch
                <template v-if="selectedBranches.length > 0">
                    <Separator orientation="vertical" class="mx-2 h-4" />
                    <Badge variant="secondary" class="rounded-sm px-1 font-normal lg:hidden">
                        {{ selectedBranches.length }}
                    </Badge>
                    <div class="hidden space-x-1 lg:flex">
                        <Badge v-if="selectedBranches.length > 2" variant="secondary" class="rounded-sm px-1 font-normal"> {{ selectedBranches.length }} selected </Badge>

                        <template v-else>
                            <Badge v-for="branch in selectedBranches" variant="secondary" class="rounded-sm px-1 font-normal">
                                {{ branch }}
                            </Badge>
                        </template>
                    </div>
                </template>
            </Button>
        </PopoverTrigger>
        <PopoverContent class="w-[200px] p-0" align="start">
            <Command>
                <!-- <CommandInput placeholder="Branch" /> -->
                <CommandList>
                    <CommandEmpty>No results found.</CommandEmpty>
                    <CommandGroup>
                        <div v-for="branch in AvailableBranches" class="flex items-center space-2 p-2">
                            <input type="checkbox" :id="branch.value" v-model="selectedBranches" :value="branch.value" />
                            <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ml-2" :for="branch.value">{{ branch.label }}</label>
                        </div>
                    </CommandGroup>
                </CommandList>
            </Command>
            <template v-if="selectedBranches.length > 0">
                <Button variant="ghost" class="justify-center text-center" @click="() => (selectedBranches = [])"> Clear Filters </Button>
            </template>
        </PopoverContent>
    </Popover>
</template>

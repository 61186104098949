<script setup lang="ts">
import AuthLayout from "@layouts/AuthLayout.vue"
import { useForm } from "@inertiajs/vue3"
import { cn } from "@/lib/utils"
import { Button } from "@components/ui/button"
import { Input } from "@components/ui/input"
import { Label } from "@components/ui/label"
import { CsrfField } from "@components/csrf-field"

defineOptions({
    layout: [AuthLayout],
})

type Props = {
    links: Links
}

const { links } = defineProps<Props>()

const form = useForm({
    email: null,
    password: null,
})

function submit() {
    form.post(links.store_path)
}
</script>

<template>
    <div :class="cn('grid gap-6', $attrs.class ?? '')">
        <form @submit.prevent="submit" method="POST">
            <CsrfField />
            <div class="grid gap-2">
                <div class="grid gap-1 mb-4">
                    <Label class="sr-only" for="email"> Email </Label>
                    <Input id="email" placeholder="name@example.com" type="email" auto-capitalize="none" auto-complete="email" auto-correct="off" v-model="form.email" />
                </div>
                <div class="grid gap-1 mb-4">
                    <Label class="sr-only" for="password"> Password </Label>
                    <Input id="password" placeholder="password" type="password" auto-capitalize="none" auto-correct="off" v-model="form.password" />
                </div>
                <Button type="submit"> Sign In </Button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { router } from "@inertiajs/vue3"
import Toaster from "@/components/ui/toast/Toaster.vue"
import { usePages } from "@/use-pages"
import { useFramesStore, TheFramesDrawer } from "@components/ui/frames"
import AppHeader from "./AppHeader.vue"

const frames = useFramesStore()

let { pages } = usePages()
pages = Object.fromEntries(Object.entries(pages).map(([path, component]) => [path.replace("./pages/", ""), component]))

window.addEventListener("popstate", () => {
    frames.frames.forEach((frame) => {
        frames.close(frame)
    })
})

router.on("navigate", () => {
    frames.frames.forEach((frame) => {
        frames.close(frame)
    })
})
</script>

<template>
    <div class="app-layout layout--app">
        <AppHeader />
        <main>
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <slot />
            </div>
        </main>

        <Toaster />

        <TheFramesDrawer :pages="pages" />
    </div>
</template>

/**
 * Returns a string formatted as a currency.
 */
export function toHuman(price, opts = {}) {
    const defaultOpts = {
        local: "en-US",
        options: {
            style: "currency",
            currency: "PHP",
        },
    }

    opts = { ...defaultOpts, ...opts }

    return new Intl.NumberFormat(opts.locale, opts.options).format(price)
}

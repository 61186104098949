<script setup lang="ts">
import { cn } from "@lib/utils"
import type { ComboboxEmptyProps } from "radix-vue"
import { ComboboxEmpty } from "radix-vue"

const props = defineProps<ComboboxEmptyProps>()
</script>

<template>
    <ComboboxEmpty v-bind="props" :class="cn('py-6 text-center text-sm', $attrs.class ?? '')">
        <slot />
    </ComboboxEmpty>
</template>

<script setup lang="ts">
import { cn } from "@lib/utils"
import { ChevronDown } from "lucide-vue-next"
import { SelectIcon, SelectTrigger, type SelectTriggerProps } from "radix-vue"

const props = withDefaults(defineProps<SelectTriggerProps & { class?: string; invalid?: boolean }>(), {
    class: "",
    invalid: false,
})
</script>

<template>
    <SelectTrigger
        v-bind="props"
        :class="[
            cn(
                'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                props.class
            ),
            props.invalid ? '!ring-destructive ring-2 placeholder:!text-destructive' : '',
        ]"
    >
        <slot />
        <SelectIcon as-child>
            <ChevronDown class="w-4 h-4 opacity-50" />
        </SelectIcon>
    </SelectTrigger>
</template>

<script setup lang="ts">
import { usePage } from "@inertiajs/vue3"
import { Link } from "@inertiajs/vue3"
import { computed } from "vue"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"

const page = usePage()

const user = computed(() => page.props.user)
const links = computed(() => page.props.inertiaLinks)

function getPath(url) {
    return url.replace(/^.*\/\/[^\/]+/, "")
}
</script>

<template>
    <nav class="border-b border-gray-200 bg-white">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 justify-between">
                <div class="flex">
                    <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                        <Link href="/" class="text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium" :class="{ 'text-gray-900 border-b-2': $page.url === '/' }">Dashboard</Link>
                        <Link
                            :href="links.transactions_path"
                            class="text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                            :class="{ 'text-gray-900 border-b-2': $page.url === getPath(links.transactions_path) }"
                            >Transactions</Link
                        >
                        <Link
                            :href="links.shifts_path"
                            class="text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                            :class="{ 'text-gray-900 border-b-2': $page.url === links.shifts_path }"
                            >Shifts</Link
                        >
                        <!-- <Link :href="links.payrolls_path" class="text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                        :class="{ 'text-gray-900 border-b-2': $page.url === getPath(links.payrolls_path)  }">Payrolls</Link> -->
                        <!-- <Link :href="links.suppliers_path" class="text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                        :class="{ 'text-gray-900 border-b-2': $page.url === links.suppliers_path }">Suppliers</Link>-->
                        <!-- <Link :href="links.employees_path" class="text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                        :class="{ 'text-gray-900 border-b-2': $page.url === links.employees_path }">Employees</Link> -->
                    </div>
                </div>

                <div class="hidden sm:ml-6 sm:flex sm:items-center">
                    <div class="relative ml-3">
                        <DropdownMenu>
                            <DropdownMenuTrigger class="text-sm font-semibold leading-6 text-gray-900">{{ user.name }} <span aria-hidden="true">&rarr;</span></DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem>Profile</DropdownMenuItem>
                                <DropdownMenuItem>Billing</DropdownMenuItem>
                                <DropdownMenuItem>Team</DropdownMenuItem>
                                <DropdownMenuItem>Subscription</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { cn } from "@lib/utils"
import type { ComboboxContentEmits, ComboboxContentProps } from "radix-vue"
import { ComboboxContent, useForwardPropsEmits } from "radix-vue"

const props = defineProps<ComboboxContentProps>()
const emits = defineEmits<ComboboxContentEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
    <ComboboxContent v-bind="forwarded" :class="cn('max-h-[300px] overflow-y-auto overflow-x-hidden', $attrs.class ?? '')">
        <div role="presentation">
            <slot />
        </div>
    </ComboboxContent>
</template>

import { toHuman } from "@helpers/formatters/currency"
import type { ColumnDef } from "@tanstack/vue-table"
import { format } from "date-fns"
import { computed, h } from "vue"
import { types } from "@/store"
import DataTableColumnHeader from "@components/DataTableColumnHeader.vue"
import DataTableRowActions from "@components/DataTableRowActions.vue"
import { Badge } from "@components/ui/badge"
import { Checkbox } from "@components/ui/checkbox"
import { labels, branches } from "./data/data"
import type { Transaction } from "./data/schema"

export const columns: ColumnDef<Transaction>[] = [
    {
        id: "select",
        header: ({ table }) =>
            h(Checkbox, { checked: table.getIsAllPageRowsSelected(), "onUpdate:checked": (value) => table.toggleAllPageRowsSelected(!!value), ariaLabel: "Select all", class: "translate-y-[2px]" }),
        cell: ({ row }) => h(Checkbox, { checked: row.getIsSelected(), "onUpdate:checked": (value) => row.toggleSelected(!!value), ariaLabel: "Select row", class: "translate-y-[2px]" }),
        enableSorting: false,
        enableHiding: false,
    },
    //   {
    //     accessorKey: 'branch',
    //     header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Branch' }),
    //     cell: ({ row }) => {
    //         return h('div', { class: 'w-[100px]' }, row.original.branch.name)
    //     },
    //   },
    //   {
    //     accessorKey: 'date',
    //     header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Date' }),
    //     cell: ({ row }) => {
    //         return h('div', { class: 'w-[100px]' }, format(row.getValue('date'), 'yyyy-MM-dd'))
    //     },
    //   },
    {
        accessorKey: "name",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Name" }),

        cell: ({ row }) => {
            const label = labels.find((label) => label.value === row.original.label)

            return h("div", { class: "flex space-x-2" }, [label && h(Badge, { variant: "outline" }, label.label), h("span", { class: "max-w-[500px] truncate font-medium" }, row.getValue("name"))])
        },
    },
    {
        accessorKey: "type",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Type" }),

        cell: ({ row }) => {
            const label = labels.find((label) => label.value === row.original.label)
            const backgroundColor = computed(() => {
                if (row.original.type.color === "red") {
                    return "bg-red-50 text-red-600 ring-red-500/10"
                }

                if (row.original.type.color === "indigo") {
                    return "bg-indigo-50 text-indigo-600 ring-indigo-500/10"
                }

                if (row.original.type.color === "green") {
                    return "bg-green-50 text-green-600 ring-green-500/10"
                }

                if (row.original.type.color === "gray") {
                    return "bg-gray-50 text-gray-600 ring-gray-500/10"
                }

                if (row.original.type.color === "yellow") {
                    return "bg-yellow-50 text-yellow-600 ring-yellow-500/10"
                }

                if (row.original.type.color === "violet") {
                    return "bg-violet-50 text-violet-600 ring-violet-500/10"
                }

                if (row.original.type.color === "brown") {
                    return "bg-stone-50 text-stone-600 ring-stone-500/10"
                }

                if (row.original.type.color === "pink") {
                    return "bg-pink-50 text-pink-600 ring-pink-500/10"
                }
            })

            return h("div", { class: "flex space-x-2" }, [
                label && h(Badge, { variant: "outline" }, label.label),
                h("span", { class: `inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${backgroundColor.value}` }, row.original.type.name),
            ])
        },
    },
    {
        accessorKey: "amount",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Amount" }),
        cell: ({ row }) => h("div", { class: "w-[100px]" }, toHuman(row.original.amount_in_cents / 100)),
    },
    //   {
    //     accessorKey: 'priority',
    //     header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Priority' }),
    //     cell: ({ row }) => {
    //       const priority = priorities.find(
    //         priority => priority.value === row.getValue('priority'),
    //       )

    //       if (!priority)
    //         return null

    //       return h('div', { class: 'flex items-center' }, [
    //         priority.icon && h(priority.icon, { class: 'mr-2 h-4 w-4 text-muted-foreground' }),
    //         h('span', priority.label),
    //       ])
    //     },
    //     filterFn: (row, id, value) => {
    //       return value.includes(row.getValue(id))
    //     },
    //   },
    {
        id: "actions",
        cell: ({ row }) => h(DataTableRowActions, { row }),
    },
]

export const transactionColumns: ColumnDef<Transaction>[] = [
    {
        id: "select",
        header: ({ table }) =>
            h(Checkbox, { checked: table.getIsAllPageRowsSelected(), "onUpdate:checked": (value) => table.toggleAllPageRowsSelected(!!value), ariaLabel: "Select all", class: "translate-y-[2px]" }),
        cell: ({ row }) => h(Checkbox, { checked: row.getIsSelected(), "onUpdate:checked": (value) => row.toggleSelected(!!value), ariaLabel: "Select row", class: "translate-y-[2px]" }),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: "branch",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Branch" }),
        cell: ({ row }) => {
            const branch = branches.find((branch) => branch.value === row.getValue("branch"))

            if (!branch) return null

            return h("div", { class: "flex w-[100px] items-center" }, [branch.icon && h(branch.icon, { class: "mr-2 h-4 w-4 text-muted-foreground" }), h("span", branch.label)])
        },
        filterFn: (row, id, value) => {
            return value.includes(row.getValue(id))
        },
    },
    {
        accessorKey: "date",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Date" }),
        cell: ({ row }) => {
            return h("div", { class: "w-[100px]" }, format(new Date(row.original.date), "yyyy-MM-dd"))
        },
        filterFn: (row, id, value) => {
            return value.includes(format(new Date(row.getValue(id)), "yyyy-MM-dd"))
        },
    },
    {
        accessorKey: "name",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Name" }),
        cell: ({ row }) => {
            const label = labels.find((label) => label.value === row.original.label)

            return h("div", { class: "flex space-x-2" }, [label && h(Badge, { variant: "outline" }, label.label), h("span", { class: "max-w-[500px] truncate font-medium" }, row.getValue("name"))])
        },
    },
    {
        accessorKey: "type",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Type" }),
        cell: ({ row }) => {
            const label = labels.find((label) => label.value === row.original.label)

            const backgroundColor = computed(() => {
                if (row.original.type.color === "red") {
                    return "bg-red-50 text-red-600 ring-red-500/10"
                }

                if (row.original.type.color === "indigo") {
                    return "bg-indigo-50 text-indigo-600 ring-indigo-500/10"
                }

                if (row.original.type.color === "green") {
                    return "bg-green-50 text-green-600 ring-green-500/10"
                }

                if (row.original.type.color === "gray") {
                    return "bg-gray-50 text-gray-600 ring-gray-500/10"
                }

                if (row.original.type.color === "yellow") {
                    return "bg-yellow-50 text-yellow-600 ring-yellow-500/10"
                }

                if (row.original.type.color === "violet") {
                    return "bg-violet-50 text-violet-600 ring-violet-500/10"
                }

                if (row.original.type.color === "brown") {
                    return "bg-stone-50 text-stone-600 ring-stone-500/10"
                }

                if (row.original.type.color === "pink") {
                    return "bg-pink-50 text-pink-600 ring-pink-500/10"
                }
            })

            return h("div", { class: "flex space-x-2" }, [
                label && h(Badge, { variant: "outline" }, label.label),
                h("span", { class: `inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${backgroundColor.value}` }, row.original.type.name),
            ])
        },
        filterFn: (row, id, value) => {
            return value.includes(row.getValue(id).name)
        },
    },
    {
        accessorKey: "amount",
        header: ({ column }) => h(DataTableColumnHeader, { column, title: "Amount" }),
        cell: ({ row }) => h("div", { class: "w-[100px] text-right" }, toHuman(row.original.amount_in_cents / 100)),
    },
    {
        id: "actions",
        cell: ({ row }) => h(DataTableRowActions, { row }),
    },
]

<script setup lang="ts">
import { addDays, format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-vue-next"
import { computed, ref } from "vue"
import { cn } from "@/lib/utils"
import { Button } from "@components/ui/button"
import { Calendar } from "@components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "@components/ui/popover"

const emit = defineEmits(["update:modelValue"])

const props = defineProps(["modelValue"])

const value = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
})
</script>

<template>
    <div :class="cn('grid gap-2', $attrs.class ?? '')">
        <Popover>
            <PopoverTrigger as-child>
                <Button id="date" :variant="'outline'" :class="cn('h-8 w-[260px] justify-start text-left font-normal', !value && 'text-muted-foreground')">
                    <CalendarIcon class="mr-2 h-4 w-4" />

                    <span>
                        {{ value.start ? (value.end ? `${format(value.start, "LLL dd, y")} - ${format(value.end, "LLL dd, y")}` : format(value.start, "LLL dd, y")) : "Pick a date" }}
                    </span>
                </Button>
            </PopoverTrigger>
            <PopoverContent class="w-auto p-0" :align="'end'" :avoid-collisions="true">
                <Calendar v-model.range="value" :columns="2" />
            </PopoverContent>
        </Popover>
    </div>
</template>

<script setup lang="ts"></script>

<template>
    <div class="[ container relative h-[800px] flex items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0 ]">
        <div class="[ relative hidden h-screen flex-col bg-muted p-10 text-white dark:border-r lg:flex ]">
            <div class="[ absolute inset-0 bg-zinc-900 ]" />
            <div class="[ relative z-20 flex items-center text-lg font-medium ]">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="mr-2 h-6 w-6">
                    <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
                </svg>
                Antahan Cafe
            </div>
            <div class="[ relative z-20 mt-auto ]">
                <blockquote class="[ space-y-2 ]">
                    <p class="[ text-lg ]">&ldquo;This library has saved me countless hours of work and helped me deliver stunning designs to my clients faster than ever before.&rdquo;</p>
                    <footer class="[ text-sm ]">Sofia Davis</footer>
                </blockquote>
            </div>
        </div>
        <div class="[ lg:p-8 w-full ]">
            <div class="[ mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px] ]">
                <div class="[ flex flex-col space-y-2 text-center mb-4 ]">
                    <h1 class="[ text-2xl font-semibold tracking-tight ]">Sign in to your account</h1>
                </div>
                <slot />
            </div>
        </div>
    </div>
</template>

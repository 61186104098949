<script setup lang="ts">
import { Cross2Icon } from "@radix-icons/vue"
import { type Table } from "@tanstack/vue-table"
import { format } from "date-fns"
import { router } from "@inertiajs/vue3"
import { computed, onMounted, ref } from "vue"
import DateRangePicker from "@/components/DateRangePicker.vue"
import { links } from "@/store"
import DataTableViewOptions from "@components/DataTableViewOptions.vue"
import { Button } from "@components/ui/button"
import { Input } from "@components/ui/input"
import { type Transaction } from "../data/schema"
import BranchFilter from "./BranchFilter.vue"
import TypeFilter from "./TypeFilter.vue"

interface DataTableToolbarProps {
    table: Table<Transaction>
}

const props = defineProps<DataTableToolbarProps>()

let isFiltered = computed(() => props.table.getState().columnFilters.length > 0)

const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
const end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
const dateRange = ref({
    start: start,
    end: end,
})
const keyword = ref()
const selectedBranches = ref([])
const selectedTypes = ref([])
const selectedDateRange = computed(() => {
    const selectedDates = []
    let currentDate = new Date(dateRange.value.start)

    while (currentDate <= new Date(dateRange.value.end)) {
        selectedDates.push(format(new Date(currentDate), "yyyy-MM-dd"))
        format(currentDate.setUTCDate(currentDate.getUTCDate() + 1), "yyyy-MM-dd")
    }

    return selectedDates
})

onMounted(() => {
    const query = new URLSearchParams(window.location.search)

    keyword.value = query.get("keyword") ?? null
    // selectedBranches.value = (query.get('branches') ?? '').split(',')
    selectedBranches.value = query.get("branches") ? query.get("branches")?.split(",") : []

    selectedTypes.value = query.get("types") ? query.get("types")?.split(",") : []
    submit()
})

function submit() {
    props.table.resetColumnFilters()

    props.table.getColumn("name")?.setFilterValue(keyword.value)
    if (selectedBranches.value.length > 0) {
        props.table.getColumn("branch")?.setFilterValue(selectedBranches.value)
    }

    if (selectedTypes.value.length > 0) {
        props.table.getColumn("type")?.setFilterValue(selectedTypes.value)
    }

    if (dateRange.value.start && dateRange.value.end) {
        props.table.getColumn("date")?.setFilterValue(selectedDateRange.value)
    }

    router.visit("/transactions", {
        method: "get",
        replace: true,
        data: {
            keyword: keyword.value,
            branches: selectedBranches.value.join(","),
            types: selectedTypes.value.join(","),
        },
        preserveScroll: true,
        preserveState: true,
    })
}

function reset() {
    props.table.resetColumnFilters()
    keyword.value = ""
    dateRange.value = {
        start: start,
        end: end,
    }
}
const viewReport = computed(() => {
    const query = new URLSearchParams()

    const branches = Array.from(selectedBranches.value)
        .filter((branch) => branch)
        .toString()

    if (dateRange.value.start && dateRange.value.end && selectedBranches) {
        query.append("branches", branches)
        query.append("start", format(new Date(dateRange.value.start), "yyyy-MM-dd"))
        query.append("end", format(new Date(dateRange.value.end), "yyyy-MM-dd"))

        return `${links.value.reports_path}?${query.toString()}`
    }

    return false
})
</script>

<template>
    <form @submit.prevent="submit">
        <div class="flex items-center justify-between">
            <div class="flex flex-1 items-center space-x-2">
                <Input placeholder="Filter transactions..." class="h-8 w-[150px] lg:w-[250px]" v-model="keyword" @input="submit" />

                <!-- <BranchFilter v-model:branches="selectedBranches" @update:branches="submit" /> -->
                <BranchFilter v-model="selectedBranches" @update:model-value="submit" />

                <TypeFilter v-model="selectedTypes" @update:model-value="submit" />

                <DateRangePicker v-model="dateRange" @update:model-value="submit" />

                <Button type="button" v-if="isFiltered" variant="ghost" class="h-8 px-2 lg:px-3" @click.prevent="reset">
                    Reset
                    <Cross2Icon class="ml-2 h-4 w-4" />
                </Button>

                <Button v-if="viewReport" as="a" :href="viewReport" class="h-8">View Report</Button>
            </div>
            <DataTableViewOptions :table="table" />
        </div>
    </form>
</template>

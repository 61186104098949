<script lang="ts">
import { type InjectionKey } from "vue"

export const FORM_ITEM_INJECTION_KEY = Symbol() as InjectionKey<string>
export const FORM_ITEM_ERROR_INJECTION_KEY = Symbol() as InjectionKey<string>
</script>

<script lang="ts" setup>
import { provide, toRefs, useAttrs } from "vue"
import { useId } from "radix-vue"
import { cn } from "@lib/utils"

defineOptions({
    inheritAttrs: false,
})

type Props = {
    error?: string
}

const props = defineProps<Props>()
const { error } = toRefs(props)

const id = useId()
provide(FORM_ITEM_INJECTION_KEY, id)
provide(FORM_ITEM_ERROR_INJECTION_KEY, error)

const { class: className, ...rest } = useAttrs()
</script>

<template>
    <div :class="cn('space-y-2', className ?? '')" v-bind="rest">
        <slot />
    </div>
</template>

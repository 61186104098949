<script setup lang="ts">
import { format } from "date-fns"
import { useForm } from "@inertiajs/vue3"
import { ref } from "vue"
import { inject } from "vue"
import { Input } from "@/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, SelectGroup } from "@/components/ui/select"
import { Separator } from "@/components/ui/separator"
import { useToast } from "@/components/ui/toast/use-toast"
import { types, employees } from "@/store"
import { Button } from "@components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "@components/ui/dialog"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@components/ui/form"

type Props = {
    transaction: transaction
}

const { transaction } = defineProps<Props>()
const { toast } = useToast()
const date = new Date(transaction.date)
const open = ref(false)
const $name = ref()
const form = useForm({
    type_id: transaction.type_id.toString(),
    name: transaction.name,
    amount: transaction.amount_in_cents / 100,
    employee_id: transaction.employee_id?.toString(),
    date: format(date, "Y-MM-dd"),
})

function submit() {
    form.put(transaction.api.update_path, {
        onSuccess() {
            toast({
                description: "Transaction successfully updated!",
            })
        },
    })
}
</script>

<template>
    <Dialog v-model:open="open">
        <DialogContent class="sm:max-w-[625px]">
            <form :action="transaction.api.update_path" @submit.prevent="submit" method="POST">
                <input type="hidden" name="_token" value="" />
                <input type="hidden" name="_method" value="PUT" />

                <DialogHeader>
                    <DialogTitle>Edit Transaction</DialogTitle>
                    <DialogDescription> Add a description about transaction here. </DialogDescription>
                </DialogHeader>
                <div class="grid gap-4 py-4">
                    <div class="grid gap-2">
                        <FormField v-slot="{ componentField }" name="name">
                            <FormItem :error="form.errors.name">
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <input
                                        type="text"
                                        name="name"
                                        ref="$name"
                                        auto-focus
                                        v-model="form.name"
                                        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                    />
                                </FormControl>
                            </FormItem>
                        </FormField>
                        <FormField v-slot="{ componentField }" name="amount">
                            <FormItem :error="form.errors.amount">
                                <FormLabel>Amount</FormLabel>
                                <FormControl>
                                    <input
                                        type="text"
                                        name="amount"
                                        auto-focus
                                        v-model="form.amount"
                                        class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                    />
                                </FormControl>
                            </FormItem>
                        </FormField>
                        <FormField name="employee" v-if="form.type_id == '6'">
                            <FormItem :error="form.errors.employee_id">
                                <FormLabel>Employee</FormLabel>

                                <Select v-model="form.employee_id">
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectGroup as="employee">
                                            <SelectItem v-for="(option, index) in employees" :key="index" :value="option.id.toString()">
                                                {{ option.name }}
                                            </SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormItem>
                        </FormField>
                    </div>
                    <Separator class="my-6" />
                    <DialogFooter class="flex sm:justify-between">
                        <div class="flex items-center space-x-4">
                            <FormField name="type">
                                <FormItem class="-mt-4 w-[150px]">
                                    <FormLabel class="sr-only">Type</FormLabel>

                                    <Select v-model="form.type_id">
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup as="type">
                                                <SelectItem v-for="(option, index) in types" :key="index" :value="option.id.toString()">
                                                    {{ option.name }}
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            </FormField>

                            <FormField v-slot="{ componentField, value }" name="date">
                                <FormItem class="-mt-4" :errors="form.errors.date">
                                    <FormLabel class="sr-only">Date</FormLabel>
                                    <FormControl>
                                        <Input type="date" v-model="form.date" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            </FormField>
                        </div>
                        <Button type="submit">Update</Button>
                    </DialogFooter>
                </div>
            </form>
        </DialogContent>
    </Dialog>
</template>

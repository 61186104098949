<script setup lang="ts">
import { DotsHorizontalIcon } from "@radix-icons/vue"
import type { Row } from "@tanstack/vue-table"
import { router, useForm } from "@inertiajs/vue3"
import { computed, ref } from "vue"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import EditTransactionDialog from "@/pages/app/transactions/EditTransactionDialog.vue"
import { Button } from "@components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "@components/ui/dropdown-menu"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@components/ui/form"
import { taskSchema } from "./data/schema"
import { type Task } from "./data/schema"

interface DataTableRowActionsProps {
    row: Row<Task>
}
const props = defineProps<DataTableRowActionsProps>()
const row = props.row.original
const open = ref(false)

function destroy() {
    router.delete(row.api.delete_path, {
        onBefore: () => confirm("Are you sure you want to delete this transaction?"),
    })
}
</script>

<template>
    <DropdownMenu>
        <DropdownMenuTrigger as-child>
            <Button variant="ghost" class="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
                <DotsHorizontalIcon class="h-4 w-4" />
                <span class="sr-only">Open menu</span>
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" class="w-[160px]">
            <DropdownMenuItem @select="open = true">Edit</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem @click="destroy">
                Delete
                <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>

    <EditTransactionDialog v-model:open="open" :transaction="row" />
</template>

<script setup lang="ts">
import { toHuman } from "@helpers/formatters/currency"
import AppLayout from "@layouts/AppLayout.vue"
import { addDays, format } from "date-fns"
import { router, useForm } from "@inertiajs/vue3"
import { provide, ref } from "vue"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { openDialog } from "@/components/ui/frames"
import DataTable from "@components/DataTable.vue"
import { columns } from "@components/columns"

defineOptions({
    layout: [AppLayout],
})

const date = new Date()
const form = useForm({
    type_id: 1,
    name: "",
    date: date.toJSON().slice(0, 10),
    amount: "",
})

type Props = {
    salaries: Salaries
    employees: Employees
    types: Types
}

const { employees, salaries, types } = defineProps<Props>()
provide("types", types)
provide("employees", employees)
</script>

<template>
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 my-10">
        <div class="flex items-center justify-between space-y-2">
            <div>
                <h2 class="text-3xl font-bold tracking-tight">All Payrolls</h2>
                <p class="text-muted-foreground">Here&apos;s a list of all payrolls and 13th month computation.</p>
            </div>
            <div class="flex items-center space-x-2"></div>
        </div>

        <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-10">
            <li v-for="employee in employees.data" :key="employee.id" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <Card>
                    <CardHeader>
                        <CardTitle class="truncate text-sm font-medium text-gray-900">
                            {{ employee.name }}
                            <span class="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{
                                employee.title
                            }}</span>
                        </CardTitle>
                        <CardDescription class="mt-1 truncate text-sm text-gray-500">{{ employee.status }}</CardDescription>
                    </CardHeader>
                    <h4 class="text-sm px-6 font-bold">{{ format(date, "MMMM Y") }}</h4>
                    <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div class="flex justify-between gap-x-4 py-3">
                            <dt class="text-gray-500">Total Salary</dt>
                            <div class="font-medium text-gray-900">{{ toHuman(employee.total_salary / 100) }}</div>
                        </div>
                        <div class="flex justify-between gap-x-4 py-3">
                            <dt class="text-gray-500">13th month pay</dt>
                            <dd class="flex items-start gap-x-2">
                                <div class="font-medium text-gray-900">{{ toHuman(employee.thirteenth_month_pay / 100) }}</div>
                            </dd>
                        </div>
                    </dl>
                </Card>
            </li>
        </ul>

        <div class="h-full flex-1 flex-col space-y-8 md:flex mt-10">
            <DataTable :data="salaries.data" :columns="columns" />
        </div>
    </div>
</template>

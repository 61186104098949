<script lang="ts" setup>
import { ErrorMessage } from "vee-validate"
import { inject } from "vue"
import { FORM_ITEM_ERROR_INJECTION_KEY } from "./FormItem.vue"
import { useFormField } from "./useFormField"

const error = inject(FORM_ITEM_ERROR_INJECTION_KEY)
</script>

<template>
    <p class="text-sm font-medium text-destructive">{{ error }}</p>
</template>

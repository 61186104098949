import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, CheckCircledIcon, CircleIcon, CrossCircledIcon, QuestionMarkCircledIcon, StopwatchIcon } from "@radix-icons/vue"
import { h } from "vue"

export const labels = [
    {
        value: "bug",
        label: "Bug",
    },
    {
        value: "feature",
        label: "Feature",
    },
    {
        value: "documentation",
        label: "Documentation",
    },
]

export const branches = [
    {
        value: "malabon",
        label: "malabon",
    },
    {
        value: "la union",
        label: "la union",
    },
]

export const types = [
    {
        value: "misc",
        label: "Misc",
        icon: h(QuestionMarkCircledIcon),
    },
    {
        value: "income",
        label: "Income",
        icon: h(CircleIcon),
    },
]

<script lang="ts" setup>
import { toHuman } from "@helpers/formatters/currency"
import { computed } from "vue"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"

type props = {
    table: table
    stats: stats
}

const props = defineProps<props>()
const salesComputed = computed(() => {
    let sales = 0
    props.table
        .getFilteredRowModel("amount")
        .rows.filter((row) => row.original.type_id == 1)
        .forEach((sale) => (sales += sale.original.amount_in_cents))

    return sales
})

const expensesComputed = computed(() => {
    let expenses = 0
    props.table
        .getFilteredRowModel("amount")
        .rows.filter((row) => row.original.type_id != 1)
        .forEach((expense) => (expenses += expense.original.amount_in_cents))

    return expenses
})

const profit = computed(() => salesComputed.value - expensesComputed.value)
const selectedBranches = computed(() => new Set(props.table.getColumn("branch")?.getFilterValue() as string[]))
const currentBalance = computed(() => {
    if (Array.from(selectedBranches.value).includes("malabon")) {
        return props.stats.malabonCurrentBalance
    }

    if (Array.from(selectedBranches.value).includes("la union")) {
        return props.stats.launionCurrentBalance
    }

    if (["malabon", "la union"].every((value) => Array.from(selectedBranches.value).includes(value))) {
        return props.stats.totalCurrentBalance
    }

    return props.stats.totalCurrentBalance
})
</script>
<template>
    <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-4 mt-6">
        <Card>
            <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle class="text-sm font-medium"> Current Balance </CardTitle>
                <span class="h-4 w-4 text-muted-foreground">₱</span>
            </CardHeader>
            <CardContent>
                <div class="text-2xl font-bold">
                    {{ toHuman(currentBalance / 100) }}
                </div>
            </CardContent>
        </Card>
        <Card>
            <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle class="text-sm font-medium"> Sales </CardTitle>
                <span class="h-4 w-4 text-muted-foreground">₱</span>
            </CardHeader>
            <CardContent>
                <div class="text-2xl font-bold">
                    {{ toHuman(salesComputed / 100) }}
                </div>
            </CardContent>
        </Card>

        <Card>
            <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle class="text-sm font-medium"> Expenses </CardTitle>
                <span class="h-4 w-4 text-muted-foreground">₱</span>
            </CardHeader>
            <CardContent>
                <div class="text-2xl font-bold">
                    {{ toHuman(expensesComputed / 100) }}
                </div>
            </CardContent>
        </Card>

        <Card>
            <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle class="text-sm font-medium"> Profit </CardTitle>
                <span class="h-4 w-4 text-muted-foreground">₱</span>
            </CardHeader>
            <CardContent>
                <div class="text-2xl font-bold">
                    {{ toHuman(profit / 100) }}
                </div>
            </CardContent>
        </Card>
    </div>
</template>

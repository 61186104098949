/**
 * Returns a date formatted for humans.
 */
export function toHuman(date, opts = {}) {
    const defaultOpts = {
        local: "en-US",
        options: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
    }

    opts = { ...defaultOpts, ...opts }

    return new Intl.DateTimeFormat(opts.locale, opts.options).format(Date.parse(date))
}
